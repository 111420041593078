// 单页面项目入口文件
import { useRoutes } from 'react-router-dom';
import BrowserLogger from 'alife-logger';
import { getValueFromUA } from '@/asset/js/utils';
import useRootSize from './asset/js/hooks/useRootSize';
import routes from './route';

const App = () => {

  const __bl = BrowserLogger.singleton({
    pid: 'dtss0nr860@b1de0f0ffe30b29',
    uid: getValueFromUA('userid'),
    // 设定日志上传地址：
    // 部署新加坡地域可设为`https://arms-retcode-sg.aliyuncs.com/r.png?`。
    // 部署美西地域可设为`https://retcode-us-west-1.arms.aliyuncs.com/r.png?`。
    imgUrl: 'https://arms-retcode-sg.aliyuncs.com/r.png?',
    // 其他config配置。
    behavior: true,
    sendResource: true,
    enableLinkTrace: true,
    environment: process.env.NODE_ENV === 'production' ? 'prod' : process.env.NODE_ENV === 'development' ? 'local' : 'gray',
    c1: getValueFromUA('userid'),
    ignore: {
      ignoreErrors: [
        function(str) {
          // 方法
          if (str && str.toLowerCase().indexOf('mapp') >= 0) {
            return true;
          }
          // 上报
          return false;
        },
        "Cannot read property 'cmd' of undefined",
        "Cannot+read+property+'cmd'+of+undefined",
        "Can't find variable: webkit",
        "Can't+find+variable:+webkit",
      ],
    },
  });
  // useRootSize(375); // 默认 设计稿 375px
  const element = useRoutes(routes);
  return element;
};

export default App;
